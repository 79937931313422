<template>
  <footer class="footer text-center py-2">
    <span class="copyright"
      >&copy; Made with &#10084; and &#9749; by
      <strong
        ><a href="https://aleksandar.it" target="_blank">AJMitev</a></strong
      ></span
    >
  </footer>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({ name: "Footer" });
</script>
