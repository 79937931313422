<template>
  <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
    <div class="container-fluid">
      <router-link to="/" class="navbar-brand">Shoppify</router-link>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarColor02"
        aria-controls="navbarColor02"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarColor02">
        <ul class="navbar-nav me-auto">
          <li class="nav-item">
            <router-link to="/" class="nav-link active" exact>
              Home
              <span class="visually-hidden">(current)</span>
            </router-link>
          </li>
        </ul>
        <div class="d-flex" v-if="user">
          <button class="btn btn-dark" type="button" @click="signOut">
            Log out
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { defineComponent } from "vue";
export default defineComponent({
  name: "Navigation",
  methods: {
    ...mapActions({
      logout: "authentication/logout",
    }),
    async signOut() {
      await this.logout();
      this.$router.push({
        name: "login",
      });
    },
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
    }),
  },
});
</script>

<style></style>
