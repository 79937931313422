import { AuthenticationService } from "@/services/authentication.service";

const service = new AuthenticationService();

export default {
  namespaced: true,
  state() {
    return {
      user: null,
    };
  },
  mutations: {
    SET_SESSION(state, session) {
      state.user = session;
    },
  },
  actions: {
    async logout({ commit }) {
      commit("SET_SESSION", null);

      localStorage.removeItem("userId");
      localStorage.removeItem("access_token");
      localStorage.removeItem("expires_at");

      await service.handleLogout();
    },
    async login({ commit }, email, password) {
      const user = await service.handleLogin({ email, password });

      if (!user) {
        return;
      }

      commit("SET_SESSION", user);
    },
    async signUp(email, paasword) {
      await service.handleSignup({ email, paasword });
    },
    updateUser({ commit }, user) {
      if (!user) {
        return;
      }

      commit("SET_SESSION", user);
    },
  },
  getters: {
    user(state) {
      return state.user;
    },
  },
};
