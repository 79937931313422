<template>
  <div class="items-wraper">
    <h1 class="text-center mt-2 mb-2 title">
      Shopping Lists for {{ this.user?.email }}
    </h1>
    <hr />
    <div class="text-center mt-3 mb-5">
      <AddItem placeholder="Name your new list" @add-item="add"></AddItem>
    </div>

    <div v-if="lists">
      <div class="items-table-wraper">
        <ul v-for="item in lists" :key="item.id" class="list-group mt-2">
          <List :list="item" />
        </ul>
      </div>
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        :itemsPerPage="pageLimit"
        @nextPage="nextPage"
        @previousPage="previousPage"
        @selectPage="selectPage"
      ></Pagination>
    </div>
    <div v-else>
      <h2>There is nothing to be shown. :(</h2>
      <div></div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions, mapGetters } from "vuex";
import List from "@/components/List.vue";
import Pagination from "@/components/Pagination.vue";
import AddItem from "@/components/AddItem.vue";

export default defineComponent({
  name: "ListsView",
  components: {
    List,
    Pagination,
    AddItem,
  },
  data() {
    return {
      currentPage: 1,
      pageLimit: 15,
    };
  },
  computed: {
    ...mapGetters({
      lists: "lists/lists",
      user: "authentication/user",
    }),
    totalPages() {
      return Math.ceil(this.lists.lengt / this.pageLimit);
    },
  },
  methods: {
    ...mapActions({
      fetch: "lists/load",
      addNew: "lists/addList",
    }),
    selectPage(n) {
      this.currentPage = n.value;
    },
    nextPage() {
      this.currentPage++;
    },
    previousPage() {
      this.currentPage--;
    },
    async add(listName) {
      const newList = { name: listName, owner: this.user.id };
      await this.addNew(newList);
    },
  },
  async beforeMount() {
    if (!this.user || !this.user.id) {
      this.$router.push({
        name: "login",
      });
      return;
    }
    await this.fetch(this.user.id);
  },
});
</script>
<style lang="scss">
.list {
  margin: 0.25rem;
}

.lists-wraper {
  min-height: 70vh;
}
</style>
