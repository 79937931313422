<template>
  <div class="text-center mt-2 mb-2">
    <div class="input-group">
      <input
        v-model="input"
        type="text"
        :placeholder="this.placeholder"
        class="form-control"
        :class="{ 'is-invalid': hasError }"
      />
      <button
        :disabled="hasError"
        @click="createNew"
        type="button"
        class="btn btn-primary"
      >
        Create
      </button>
      <div class="invalid-feedback" v-if="hasError">
        {{ errorMessage }}
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "AddItem",
  props: ["placeholder"],
  emits: ["add-item"],
  data() {
    return {
      input: "",
      errorMessage: "",
      isDirty: false,
    };
  },
  watch: {
    input(newInput, oldInput) {
      if (newInput && newInput !== oldInput) {
        this.isDirty = true;
        this.validateInput();
      }
    },
  },
  methods: {
    createNew() {
      this.validateInput();

      if (this.errorMessage) {
        return;
      }

      this.$emit("add-item", this.input);
      this.input = "";
      this.isDirty = false;
    },
    validateInput() {
      this.errorMessage = "";

      if (!this.input) {
        this.errorMessage = "This field cannot be empty!";
      }

      if (this.input && this.input.length <= 2) {
        this.errorMessage = "This field should be more than two symbols!";
      }
    },
  },
  computed: {
    hasError() {
      return this.isDirty && !!this.errorMessage;
    },
  },
});
</script>

<style lang="scss" scoped>
.form-control {
  width: 30%;
  display: inline-block;
}

.input-group {
  margin: 0 auto;
  display: block;

  input {
    width: 30%;
  }
}

@media only screen and (max-width: 1000px) {
  .input-group {
    input {
      width: 80%;
    }
  }
}
</style>
