<template>
  <div class="row" :class="{ blured: purchase.isBought }">
    <div class="col-9">
      <label>
        <input
          @click="updateStatus(purchase)"
          class="cursor-pointer text-center"
          type="checkbox"
          :checked="purchase.isBought"
        />
        <span class="item-name text-secondary">
          {{ purchase.name }}
        </span>
      </label>
    </div>
    <div class="col-2">
      <button @click="deleteItem" class="btn btn-outline-danger float-right">
        X
      </button>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";

export default defineComponent({
  name: "Purchase",
  props: ["purchase"],
  methods: {
    ...mapActions({
      deletePurchase: "lists/deletePurchase",
      updatePurchase: "lists/updatePurchaseStatus",
    }),
    async deleteItem() {
      await this.deletePurchase(this.purchase);
    },
    async updateStatus(purchase) {
      const newStatus = !purchase.isBought;
      await this.updatePurchase(purchase.id, newStatus);
    },
  },
});
</script>

<style lang="scss" scoped>
label {
  cursor: pointer;
  width: 100%;
}

.blured {
  font-style: italic;
  opacity: 50%;
}

.item-name {
  font-weight: 800;
  font-size: 1.3rem;
  vertical-align: middle;
  padding-top: 0.2rem;
  padding-left: 2.5rem;
}

input {
  vertical-align: middle;
}
</style>
