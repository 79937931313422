<template>
  <a @click="open">
    <li
      class="list-group-item d-flex justify-content-between align-items-center"
    >
      {{ list.name }}
      <span
        class="badge rounded-pill"
        :class="{
          'bg-primary': boughtItems !== totalItems,
          'bg-success': boughtItems == totalItems,
          'bg-warning': totalItems == 0,
        }"
        >{{ boughtItems }} / {{ totalItems }}</span
      >
    </li>
  </a>
</template>

<script>
import { defineComponent } from "vue";
import { mapActions } from "vuex";

export default defineComponent({
  name: "List",
  props: {
    list: {},
  },
  computed: {
    totalItems() {
      return this.list.items.length;
    },
    boughtItems() {
      return this.list.items.filter((x) => x.isBought).length;
    },
  },
  methods: {
    ...mapActions({
      delete: "lists/delete",
    }),
    async deleteItem() {
      await this.delete(this.$props.list);
    },
    open() {
      this.$router.push({
        name: "list",
        params: { id: this.list.id },
      });
    },
  },
});
</script>

<style lang="scss" scoped>
a {
  cursor: pointer;
}
</style>
