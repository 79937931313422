import { createStore } from "vuex";

import authentication from "@/store/modules/authentication.js";
import lists from "@/store/modules/lists.js";

const store = createStore({
  modules: {
    authentication,
    lists,
  },
});

export default store;
