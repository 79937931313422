import { createRouter, createWebHistory } from "vue-router";
import ListsView from "@/views/ListsView.vue";
import LoginView from "@/views/LoginView.vue";
import GroceriesListView from "@/views/GroceriesListView.vue";

function authenticatedOnly(to, from, next) {
  const userId = localStorage.getItem("userId");
  const token = localStorage.getItem("access_token");
  const isAuthenticated = userId && token;

  if (isAuthenticated) {
    next();
  } else {
    next("/login");
  }
}

const routes = [
  {
    path: "/login",
    name: "login",
    component: LoginView,
  },
  {
    path: "/list/:id",
    name: "list",
    component: GroceriesListView,
    beforeEnter: authenticatedOnly,
  },
  {
    path: "/",
    name: "home",
    component: ListsView,
    beforeEnter: authenticatedOnly,
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
