import { ListService } from "@/services/lists.service";
import { PurchaseService } from "@/services/purchase.service";

const listService = new ListService();
const purchaseService = new PurchaseService();

export default {
  namespaced: true,
  state() {
    return {
      lists: [],
    };
  },
  mutations: {
    SET_LISTS(state, items) {
      state.lists = items;
    },
    ADD_LIST(state, list) {
      if (!list) {
        return;
      }

      state.lists.push(list);
    },
    DELETE_LIST(state, listId) {
      if (!listId) {
        return;
      }

      state.lists = state.lists.filter((list) => list.id != listId);
    },
    ADD_PURCHASE(state, purchase) {
      const list = state.lists.find((list) => list.id == purchase.listId);

      if (!list) {
        return null;
      }

      list.items.push(purchase);
    },
    DELETE_PURCHASE_BY_ID(state, id) {
      const list = state.lists.find((list) =>
        list.items.find((i) => i.id == id)
      );

      list.items = list.items.filter((purchase) => purchase.id != id);
    },
    UPDATE_PURCHASE_STATUS(state, id) {
      const item = state.lists.find((list) =>
        list.items.find((purchase) => purchase.id == id)
      );

      if (item) {
        const purchase = item.items.find((p) => p.id == id);
        if (purchase) {
          purchase.isBought = !purchase.isBought;
        }
      }
    },
  },
  actions: {
    async load({ commit }, userId) {
      const data = await listService.fetch(userId);
      commit("SET_LISTS", data);
    },
    async deletePurchase({ commit }, purchase) {
      await purchaseService.deletePurchase(purchase);
      commit("DELETE_PURCHASE_BY_ID", purchase.id);
    },
    async updatePurchaseStatus({ commit, getters }, purchaseId) {
      const purchase = getters.getPurchaseById(purchaseId);
      await purchaseService.updatePurchaseStatus(
        purchaseId,
        !purchase.isBought
      );
      commit("UPDATE_PURCHASE_STATUS", purchaseId);
    },
    async addPurchase({ commit }, purchase) {
      if (!purchase || !purchase.name || !purchase.listId) {
        return;
      }

      const entity = await purchaseService.addPurchase(purchase);

      if (!entity) {
        return;
      }
      if (entity) {
        commit("ADD_PURCHASE", entity);
      }
    },
    async addList({ commit }, list) {
      if (!list) {
        return;
      }

      const insertedList = await listService.add(list);
      insertedList.items = [];

      commit("ADD_LIST", insertedList);
    },
    async deleteList({ commit }, listId) {
      await listService.delete(listId);
      commit("DELETE_LIST", listId);
    },
  },
  getters: {
    lists(state) {
      return state.lists;
    },
    pageLimit(state) {
      return state.pageLimit;
    },
    getById: (state) => (id) => {
      return state.lists.find((x) => x.id == id);
    },
    getPurchaseById: (state) => (id) => {
      const list = state.lists.find((list) =>
        list.items.find((purchase) => purchase.id == id)
      );

      if (!list) {
        return null;
      }

      return list.items.find((p) => p.id == id);
    },
  },
};
