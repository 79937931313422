import supabase from "@/lib/supabase";

export class PurchaseService {
  /**
   * Retrieve all Purchases for the signed in user
   */
  async fetchPurchases(listId) {
    try {
      const { data, error } = await supabase
        .from("items")
        .select("*")
        .eq("listId", listId);

      if (error || data === null) {
        console.error("error", error);
        return undefined;
      }

      return data;
    } catch (err) {
      console.error("Error retrieving data from db", err);
    }
  }

  /**
   *  Add a new Purchase to supabase
   */
  async addPurchase(purchase) {
    try {
      const { data, error } = await supabase
        .from("items")
        .insert(purchase)
        .single();

      if (error) {
        alert(error.message);
        console.error("There was an error inserting", error);
        return null;
      }

      return data;
    } catch (err) {
      alert("Error");
      console.error("Unknown problem inserting to db", err);
      return null;
    }
  }

  /**
   * Targets a specific item via its record id and updates the is_completed attribute.
   */
  async updatePurchaseStatus(purchaseId, isBought) {
    try {
      const { error } = await supabase
        .from("items")
        .update({ isBought: isBought })
        .eq("id", purchaseId)
        .single();

      if (error) {
        alert(error.message);
        console.error("There was an error updating", error);
        return;
      }
    } catch (err) {
      alert("Error");
      console.error("Unknown problem updating record", err);
    }
  }

  /**
   *  Deletes a item via its id
   */
  async deletePurchase(purchase) {
    try {
      await supabase.from("items").delete().eq("id", purchase.id);
    } catch (error) {
      console.error("error", error);
    }
  }
}
