<template>
  <Suspense>
    <template #default>
      <div>
        <Navigation />
        <main class="container-md">
          <router-view />
        </main>
        <Footer />
      </div>
    </template>
    <template #fallback>
      <Loading />
    </template>
  </Suspense>
</template>

<script>
import Footer from "@/components/Footer.vue";
import supabase from "@/lib/supabase";
import Loading from "@/components/Loading.vue";
import Navigation from "@/components/Navigation.vue";
import { mapGetters, mapActions } from "vuex";

export default {
  components: {
    Footer,
    Loading,
    Navigation,
  },
  computed: {
    ...mapGetters({
      user: "authentication/user",
    }),
  },
  methods: {
    ...mapActions({
      handleLogout: "authentication/logout",
      updateUser: "authentication/updateUser",
      load: "lists/load",
    }),
  },
  beforeMount() {
    /**
     * Keeps track of if the user is logged in or out and will update userSession state accordingly.
     */
    supabase.auth.onAuthStateChange((event, session) => {
      if (!session || !session.user || this.user) {
        return;
      }

      localStorage.setItem("userId", session.user.id);
      localStorage.setItem("access_token", session.access_token);
      localStorage.setItem("expires_at", session.expires_at);

      this.updateUser(session?.user);

      if (this.$route.name === "login") {
        this.$router.push({
          path: "/",
        });
      }
    });
  },
  async created() {
    if (this.user) {
      await this.load(this.user.id);
    }
  },
};
</script>
<style lang="scss">
#app {
  height: 100%;
  width: 100%;
  margin: 0;
}

html,
body {
  height: 100%;
  width: 100%;
  margin: 0;

  display: flex;
}

main {
  min-height: 80vh;
}

input {
  width: 30%;
}
.items-wraper {
  min-height: 88vh;
}

.items-table-wraper {
  min-height: 75vh;
}
</style>
