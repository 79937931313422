<template>
  <div class="row text-center mt-2" v-if="totalPages > 1">
    <ul class="pagination">
      <li class="page-item" :class="{ disabled: currentPage == 1 }">
        <a class="page-link" @click="this.$emit('previousPage')">&laquo;</a>
      </li>
      <li
        class="page-item"
        v-for="n in totalPages"
        :key="n"
        :class="{ active: n == currentPage }"
      >
        <a class="page-link" @click="this.$emit('selectPage', { value: n })">{{
          n
        }}</a>
      </li>
      <li class="page-item" :class="{ disabled: currentPage == totalPages }">
        <a class="page-link" @click="this.$emit('nextPage')">&raquo;</a>
      </li>
    </ul>
  </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
  name: "Pagination",
  props: ["currentPage", "totalPages", "itemsPerPage"],
  emits: ["selectPage", "nextPage", "previousPage", "totalPagesChanged"],
});
</script>

<style></style>
