<template>
  <div class="items-wraper">
    <div class="list-heading">
      <h4 class="mt-4 mb-2 text-muted">
        <em><router-link to="/">Back</router-link></em>
      </h4>
      <h1 class="text-center mt-2 mb-2 title">
        {{ this.currentList.name }}
      </h1>
      <h4 class="mt-4 mb-2 text-danger">
        <em
          ><a class="text-danger btn-delete" @click="deleteList">Delete</a></em
        >
      </h4>
    </div>
    <AddItem
      placeholder="What do you need to buy?"
      @add-item="createNew"
    ></AddItem>
    <div v-if="purchases.length">
      <div class="items-table-wraper">
        <ul v-for="purchase in purchases" :key="purchase.id">
          <Purchase :purchase="purchase" />
        </ul>
      </div>
      <Pagination
        :currentPage="currentPage"
        :totalPages="totalPages"
        :itemsPerPage="pageLimit"
        @nextPage="nextPage"
        @previousPage="previousPage"
        @selectPage="selectPage"
      ></Pagination>
    </div>
    <div v-else class="items-table-wraper">
      <div class="wrapper">
        <div class="alert alert-dismissible alert-secondary">
          <strong>Well done!</strong> You successfully created a new planning
          list. Now it is time to start putting things on it!
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Purchase from "@/components/Purchase.vue";
import Pagination from "@/components/Pagination.vue";
import AddItem from "@/components/AddItem.vue";
import { mapActions, mapGetters } from "vuex";

export default defineComponent({
  name: "GroceriesListView",
  components: {
    Purchase,
    Pagination,
    AddItem,
  },
  data() {
    return {
      currentList: {},
      currentPage: 1,
      pageLimit: 15,
    };
  },
  computed: {
    ...mapGetters({
      getById: "lists/getById",
      lists: "lists/lists",
      user: "authentication/user",
    }),
    purchases() {
      const start = (this.currentPage - 1) * this.pageLimit;
      const end = this.pageLimit + start;
      const purchases = this.currentList?.items;

      if (!purchases) {
        return null;
      }

      const sorted = purchases.slice().sort((a, b) => a.isBought - b.isBought);
      return sorted.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.currentList.items.length / this.pageLimit);
    },
  },
  methods: {
    ...mapActions({
      fetchLists: "lists/load",
      addPurchase: "lists/addPurchase",
      delete: "lists/deleteList",
    }),
    selectPage(data) {
      this.currentPage = data.value;
    },
    nextPage() {
      this.currentPage++;
    },
    previousPage() {
      this.currentPage--;
    },
    async createNew(itemText) {
      await this.addPurchase({
        name: itemText,
        listId: this.currentList.id,
      });

      this.input = "";
    },
    async deleteList() {
      await this.delete(this.currentList.id);
      this.$router.push({
        name: "home",
      });
    },
  },
  async created() {
    const listId = this.$route.params.id;
    if (!listId) {
      console.error("Invalid listId");
      //TODO: Redirect to error page;
    }

    if (this.lists.length == 0) {
      await this.fetchLists(this.user.id);
    }

    const list = this.getById(listId);
    this.currentList = list;
  },
});
</script>

<style lang="scss" scoped>
.list-heading {
  display: flex;
}

.title {
  width: 100%;
}

.wrapper {
  height: 100%;
  margin-top: 4rem;
}

.btn-delete {
  cursor: pointer;
}
</style>
